import * as React from 'react'
import { Subheader } from 'components/common'
import { Page } from '../page'
import * as cls from './styles.module.css';

export const Article = ({ 
  title, 
  subtitle,
  children
}) => {
  return (
    <Page>
      <h2>{title}</h2>
      { subtitle ? <Subheader>{subtitle}</Subheader> : undefined }
      <div className={cls.content}>
        {children}
      </div>
    </Page>
  )
}