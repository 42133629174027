import * as React from 'react'
import { Article as ArticleLayout } from 'components/layout/sub/article'
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react';

export const Article = ({
  data: {
    mdx: {
      frontmatter,
      body,
    },
  },
}) => {
  return (
    <ArticleLayout 
      title={frontmatter.title}
      subtitle={frontmatter.subtitle}
    >
      <MDXProvider>
        <MDXRenderer
          remoteImages={frontmatter.embeddedImagesRemote}
          localImages={frontmatter.embeddedImagesLocal}
          frontmatter={frontmatter}
        >
          {body}
        </MDXRenderer>
      </MDXProvider>
    </ArticleLayout>
  )
}

export default Article;

export const query = graphql`
query ($id: String) {
  mdx(id: {eq: $id}) {
    frontmatter {
      order
      title
      subtitle
      description
      type
      visible
      tags
      date
      embeddedImagesRemote {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      embeddedImagesLocal {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    slug
    body
  }
}
`;